// https://theme-ui.com/theme-spec

/* Misc Notes:
  1. heading refers to h1, h2, h3, etc. Not the header/footer
  10. This file is executed once Serverside, and once Clientside
*/

/////    Brand Specific Color Settings    /////

const lumePrimaryPalette = {

  primaries: {
    blue: '#00a3bf',
    purple: '#ad0da6',
    darkGrey: '#4d5254',
    black: '#000'
  },
  backgrounds: {
    blue: 'rgba(115, 209, 223, 0.8)',
    lightBlue: '#E5F7FA',
    darkBlue: '#2196f3',
    lightPurple: '#F2DDFC',
    teal: 'rgba(0, 159, 157, 0.5)',
    white: '#FFF',
  },
  secondaries: {
    evergreenBg2: '#9383CF',
    evergreenText2: '#5544B9', // footer.p - "Meet Flamingo", "Questions? We've got answers", etc
    secondarypurple: '#33077E',
    bodyMoistureBg1: '#0C413B',
    bodyMoistureText1: '#0C413B', // also Wax Info Tablist
  },
  fonts: {
    poppins: 'Poppins, Helvetica, Arial, sans-serif',
    poppinsSemiBold: 'Poppins-SemiBold, Helvetica, Arial, sans-serif',
    poppinsBold: 'Poppins-Bold, Helvetica, Arial, sans-serif',
    poppinsBlackHeavy: 'Poppins-BlackHeavy, Helvetica, Arial, sans-serif',
    lato: 'Lato, Helvetica, Arial, sans-serif',
    latoLight: 'Lato-Light, Helvetica, Arial, sans-serif',
    menlo: 'Menlo, monospace',
  },

  referenceColors: {
    text: '#2B1453', // primary.evergreenText1,
    body: '#4e4e4e', // grays.gray70,
    heading: '#ad0da6', // primaries.evergreenBg5,
    background: '#FFF', 
    primary: '#2B1453', // primaries.evergreenBg1,
    secondary: '#9383CF', // secondaries.evergreenBg2,
    muted: '#bfbfbf', // grays.gray40,
    navList: '#5544B9', // secondaries.evergreenText2
    headerCTA: '#2B1453', // primaries.evergreenBg1
    link: '#3ca6e0', 
  }
};


// Theme config exported for access in components, build loop, and builder configs
const lumeStyles = {
  referenceColors: lumePrimaryPalette.referenceColors,
  breakpoints: ['48em', '64em', '90em'],
  space: {
    none: '0',
    small: ['0.375em', '0.375em', '0.75em'], // Arrays set responsive values from smallest breakpoint to largest
    medium: ['0.5em', '0.5em', '1em'],
    large: ['0.75em', '0.75em', '1.5em'],
    xlarge: ['1.2em', '1.5em', '3em'],
    mega: ['4em', '4em', '8em'],
  },
  sizes: {
    container: ['100%', '43.25em', '61.25em', '76.25em'],
    viewHeight100: '100vh',
    viewWidth100: '100vw',
    navHeight: '100px',
    footerHeight: '213px',
  },
  fonts: {
    body: lumePrimaryPalette.fonts.lato,
    heading: lumePrimaryPalette.fonts.poppins,
    monospace: lumePrimaryPalette.fonts.menlo,
    nav: lumePrimaryPalette.fonts.lato,
  },
  
  fontSizes: [16, 20, 24, 28, 30, 32, 36, 40, 38, 48],
  fontWeights: {
    body: 300,
    heading: 500,
    bold: 700,
  },
  lineHeights: {
    body: 1.75,
    heading: 1.25,
    button: 1.5,
  },
  letterSpacings: {
    body: 0.5,
    heading: 0.9,
    caps: '0.125em',
    caption: '0.0625em',
  },
  // User exposed color options in the Builder UI are also drawn from this theme
  // Color must be defined here to be used in backgroundColors and other sub categories
  colors: { 
    text: lumePrimaryPalette.primaries.darkGrey,
    body: 'rgb(67, 84, 83)',
    heading: lumePrimaryPalette.primaries.darkGrey,
    white: lumePrimaryPalette.primaries.white,
    primary: lumePrimaryPalette.primaries.evergreenText1,
    secondary: lumePrimaryPalette.secondaries.evergreenBg2,
    muted: lumePrimaryPalette.primaries.darkGrey,
    link: lumePrimaryPalette.secondaries.evergreenText2,

    // Backgrounds
    blue: lumePrimaryPalette.backgrounds.blue,
    lightBlue: lumePrimaryPalette.backgrounds.lightBlue,
    lightPurple: lumePrimaryPalette.backgrounds.lightPurple,
    teal: lumePrimaryPalette.backgrounds.teal,

    // Text
    blue_t: lumePrimaryPalette.primaries.blue,
    purple_t: lumePrimaryPalette.primaries.purple,
    darkGrey_t: lumePrimaryPalette.primaries.darkGrey,
    
    transparent: 'transparent',
  },
  // Used for component background colors
  backgroundColors: {
    blue: lumePrimaryPalette.backgrounds.brightBlue,
    lightBlue: lumePrimaryPalette.backgrounds.lightBlue,
    lightPurple: lumePrimaryPalette.backgrounds.lightPurple,
    teal: lumePrimaryPalette.backgrounds.teal,
    white: lumePrimaryPalette.backgrounds.white,
    transparent: 'transparent',
  },
  radii: {
    card: ['0.25em', '0.5em'],
    button: ['6px', '6px'],
  },
  shadows: {
    card: '0 0.125em 0.1875em 0 rgba(0,0,0,0.2)',
  },
  text: {
    body: {
      fontFamily: 'body',
      fontWeight: 'body',
      lineHeight: 'body',
      letterSpacing: 'body',
    },
    heading: {
      fontFamily: 'heading',
      fontWeight: 'heading',
      lineHeight: 'heading',
      letterSpacing: 'heading',
    },
  },
  // theme variants
  buttons: {
    primary: {
      bg: '#2196f3',
      borderRadius: 'button',
      color: '#fff',
      fontFamily: lumePrimaryPalette.fonts.lato,
      fontSize: 16,
      fontStyle: 'normal',
      fontWeight: '400',
      lineHeight: 'button',
      letterSpacing: 'inherit',
      marginBottom: 'medium',
      padding: ['0.75em', '0.75em 3em'],
      verticalAlign: 'middle',
      WebkitTransition: 'ease all .2s',
      transition: 'ease all .2s',
      whiteSpace: 'nowrap',
      '&:hover': {
        bg: '#0960a5',
      },
      '& + a': {
        marginLeft: ['none', 'none', 'small'],
      },
    },
    secondary: {
      variant: 'buttons.primary',
      font: lumePrimaryPalette.fonts.latoLight,
      bg: '#2196f3',
      padding: '0.8em 2em',
      borderRadius: '50px',
      fontWeight: '400',
      textTransform: 'uppercase',
    },
    hollow: {
      variant: 'buttons.primary',
      bg: 'transparent',
      color: 'heading',
      border: '1px solid',
      transition: 'background-color .15s, color .15s',
      '&:hover': {
        bg: 'heading',
        color: 'background',
      },
    },
    link: {
      variant: 'buttons.primary',
      paddingLeft: 0,
      paddingRight: 0,
      bg: 'transparent',
      color: 'heading',
      textDecoration: 'underline',
      '&:hover': {
        bg: 'transparent',
      },
    },
    bodyMoisture: {
      variant: 'buttons.primary',
      bg: 'bodyMoistureBg2',
      '&:hover': {
        bg: 'bodyMoistureBg14',
      },
    },
    pink: {
      variant: 'buttons.primary',
      bg: '#DE1B83',
      '&:hover': {
        bg: '#D1197B',
      },
    },
    orange: {
      variant: 'buttons.primary',
      bg: '#FE5000',
      '&:hover': {
        bg: '#E74C05',
      },
    },
  },
  // markdown styles
  styles: {
    root: {
      header: {
        fontSize: '11px',
      },
      variant: 'text.body',
      h1: {
        variant: 'text.heading',
        fontWeight: 'bold',
        fontSize: [6,7],
        fontFamily: lumePrimaryPalette.fonts.poppinsBlackHeavy, 
        lineHeight: '1.2',
        letterSpacing: '0',
        textTransform: 'none',
        margin: '0',
      },
      h2: {
        variant: 'text.heading',
        fontSize: [5,6],
        fontFamily: lumePrimaryPalette.fonts.poppinsBold, 
        // fontWeight: '300',
        lineHeight: ['104%', '144%'],
        letterSpacing: '0px',
        margin: '0',
      },
      h3: {
        variant: 'text.heading',
        fontSize: [3,4],
        fontFamily: lumePrimaryPalette.fonts.poppinsBold,
        fontWeight: '300',
        lineHeight: ['119%', '112%'],
        letterSpacing: '0px',
        margin: '0',
      },
      h4: {
        variant: 'text.heading',
        fontSize: [3],
        fontFamily: lumePrimaryPalette.fonts.poppinsBold,
        lineHeight: '148%',
        letterSpacing: '1px',
        // fontWeight: 300,
        margin: 0,
      },
      h5: {
        variant: 'text.heading',
        fontSize: [1],
        fontFamily: lumePrimaryPalette.fonts.poppinsBold,
        lineHeight: ['142%', '150%'],
        fontWeight: '300px',
        letterSpacing: '-0.25px',
        margin: '0',
      },
      h6: {
        fontSize: [0],
        fontFamily: lumePrimaryPalette.fonts.poppinsBold,
        fontWeight: '400',
        lineHeight: ['140%', '148%'],
        textTransform: 'none',
        letterSpacing: '-0.15px',
        margin: '0',
      },
      p: {
        lineHeight: '150%',
        fontSize: '16px',
        // letterSpacing: '-0.15px',
        // margin: 0,
      },
      a: {
        color: 'link',
      },
      table: {
        borderCollapse: 'collapse',
        borderSpacing: 0,
      },
      tr: {
        fontSize: '1.1em',
      },
      th: {
        color: 'heading',
        padding: '0.4em',
        borderBottom: '2px solid rgba(0,0,0,0.2)',
        textTransform: 'uppercase',
        verticalAlign: 'middle',
      },
      td: {
        borderBottom: '1px solid rgba(0,0,0,0.1)',
        padding: '0.4em',
        lineHeight: '1.8em',
        verticalAlign: 'middle',
        '& p': {
          fontSize: '80%',
          margin: '0.4em 0',
          lineHeight: '1.3',
        },
      },
    },
    textStyles: ['default', 'dropshadowWhite', 'dropshadowPurple', 'dropshadowTeal', 'quote'],
    textColors: ['default', 'blue_t', 'purple_t', 'darkGrey_t', 'white'],
    default: {
      h1: { 
        color: 'heading', 
      },
      h2: { 
        color: 'heading', 
      },
      h3: { 
        color: 'heading',
      },
      h4: { 
        color: 'heading',
      },
      h5: { 
        color: 'heading',
      },
      h6: { 
        color: 'heading'
      },
      p: {
        color: 'body' 
      }
    },
    dropshadowPurple: {
      h1: { 
        textShadow: '0.085106383em 0.085106383em 0 #ad0da6',
        WebkitTextStroke: '0.0394736842em #ad0da6',
      },
      h2: { 
        textShadow: '0.085106383em 0.085106383em 0 #ad0da6',
        WebkitTextStroke: '0.0394736842em #ad0da6',
      },
      h3: { 
        textShadow: '0.085106383em 0.085106383em 0 #ad0da6',
        WebkitTextStroke: '0.0394736842em #ad0da6',
      },
      h4: { 
        textShadow: '0.085106383em 0.085106383em 0 #ad0da6',
        WebkitTextStroke: '0.0394736842em #ad0da6',
      },
      h5: { 
        textShadow: '0.085106383em 0.085106383em 0 #ad0da6',
        WebkitTextStroke: '0.0394736842em #ad0da6',
      },
      h6: { 
        textShadow: '0.085106383em 0.085106383em 0 #ad0da6',
        WebkitTextStroke: '0.0394736842em #ad0da6',
      },
    },
    dropshadowWhite: {
      h1: { 
        textShadow: '0.085106383em 0.085106383em 0 #fff',
      },
      h2: { 
        textShadow: '0.085106383em 0.085106383em 0 #fff',
      },
      h3: { 
        textShadow: '0.085106383em 0.085106383em 0 #fff',
      },
      h4: { 
        textShadow: '0.085106383em 0.085106383em 0 #fff',
      },
      h5: { 
        textShadow: '0.085106383em 0.085106383em 0 #fff',
      },
      h6: { 
        textShadow: '0.085106383em 0.085106383em 0 #fff',
      },
    },
    dropshadowTeal: {
      h1: { 
        textShadow: '4px 4px 2px #009f9d',
      },
      h2: { 
        textShadow: '4px 4px 2px #009f9d',
      },
      h3: { 
        textShadow: '4px 4px 2px #009f9d',
      },
      h4: { 
        textShadow: '4px 4px 2px #009f9d',
      },
      h5: { 
        textShadow: '4px 4px 2px #009f9d',
      },
      h6: { 
        textShadow: '4px 4px 2px #009f9d',
      },
    },
    quote: {
      borderLeft: 'solid 2px #008AA2',
      paddingLeft: '20px',
      color: '#981b90',
      fontFamily: 'Poppins',
      lineHeight: '1.5',
      fontStyle: 'italic',
    }
  },
};

export default lumeStyles;